import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC, useEffect } from 'react';
import { InputDialog } from 'wix-ui-tpa';
import { DataHook } from '../../../../../constants/DataHook';
import {
  BiActionType,
  ModalType,
  MY_ACCOUNT_REFERRAL,
} from '../../../../../types';
import { classes } from './NewBlockedMembersModal.st.css';
import { NewActionableMemberRow } from '../../common/ActionableMemberRow';
import { useMember, useMemberActions } from '../../../../../contexts/widget';
import { useBi } from '../../../../../hooks';

export const NewBlockedMembersModal: FC<ModalType> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { blockedMembers } = useMember();
  const { unblockMember } = useMemberActions();
  const biLogger = useBi();

  useEffect(() => {
    if (!blockedMembers.length) {
      onClose();
    }
  }, [blockedMembers.length, onClose]);

  const onUnblock = (id: string, name: string) => {
    biLogger.ctaButtonClickedWithProps(BiActionType.Unblock_Member_Confirm, {
      member_chosen: id,
      formOrigin: MY_ACCOUNT_REFERRAL,
    });
    return unblockMember({ id, name });
  };

  return (
    <InputDialog
      className={classes.root}
      isOpen={isOpen}
      onClose={onClose}
      title={t('app.widget.modals.blocked-members.title')}
      subtitle={t('app.widget.modals.blocked-members.subtitle')}
      ariaLabel={t('app.widget.modals.blocked-members.title')}
      primaryButton={{
        text: t('app.widget.modals.blocked-members.close-btn'),
        onClick: onClose,
      }}
      closeButtonAriaLabel={t('app.widget.modals.blocked-members.close-btn')}
    >
      <div className={classes.content} data-hook={DataHook.BlockedMembersModal}>
        {blockedMembers.map((member) => (
          <NewActionableMemberRow
            member={member}
            onAction={() => onUnblock(member.id, member.name)}
            buttonText={t('app.widget.modals.blocked-members.unblock')}
          />
        ))}
      </div>
    </InputDialog>
  );
};
