import React, { FC } from 'react';
import { Section } from '@wix/ambassador-members-v1-custom-field/types';

import { classes } from './AddressSection.st.css';
import { FieldGroup } from '../../FieldGroup';
import { useFormFields } from '../../../../../contexts/form';

export const AddressSection: FC<React.PropsWithChildren<unknown>> = () => {
  const fields = useFormFields();

  return (
    <div className={classes.root}>
      <FieldGroup fields={fields} section={Section.ADDRESS} />
    </div>
  );
};
