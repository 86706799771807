import React from 'react';
import { TFunction } from '@wix/yoshi-flow-editor';
import {
  Text,
  Tooltip,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
  TextButton,
  Accordion,
} from 'wix-ui-tpa';

import { classes } from './CommunityPageUrl.st.css';
import { EditProfileUrlModal } from '../../../../modals';
import { DataHook } from '../../../../../../../constants/DataHook';
import { useBi } from '../../../../../../../hooks/useBi';
import { BiActionType } from '../../../../../../../types';
import { ModalContextType } from '../../../../../../../contexts/modals/Provider';

const COMMUNITY_PAGE_URL_ITEM_ID = 'communityPageUrl';

type Props = {
  t: TFunction;
  showModal: ModalContextType['showModal'];
  biLogger: ReturnType<typeof useBi>;
  baseUrl: string;
  slug: string;
};

export const getCommunityPageUrlItem = ({
  t,
  baseUrl,
  slug,
  showModal,
  biLogger,
}: Props) => {
  const communityUrl = `${baseUrl}/profile/${slug}/profile`;

  const onEditProfileUrlClick = () => {
    biLogger.ctaButtonClicked(BiActionType.Edit_Url);
    showModal(EditProfileUrlModal);
  };

  return (
    <Accordion.Item
      id={COMMUNITY_PAGE_URL_ITEM_ID}
      title={t('app.widget.community-url.title')}
      data-hook={DataHook.ProfileUrlAccordionItem}
    >
      <div>
        <Tooltip className={classes.tooltip} content={communityUrl}>
          <Text className={classes.content} tagName="div">
            {communityUrl}
          </Text>
        </Tooltip>
      </div>
      <TextButton
        className={classes.cta}
        priority={TEXT_BUTTON_PRIORITY.link}
        onClick={onEditProfileUrlClick}
        data-hook={DataHook.EditProfileUrlButton}
      >
        {t<string>('app.widget.community-url.cta')}
      </TextButton>
    </Accordion.Item>
  );
};
