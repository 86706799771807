import { CustomField } from '@wix/ambassador-members-v1-custom-field/types';
import {
  Address,
  Contact,
  Member,
} from '@wix/ambassador-members-v1-member/types';
import { FieldPrivacy } from '@wix/ambassador-members-v1-member-custom-field-privacy/types';

import { getCustomFieldWithValue } from './customFields';
import { AccountData } from '../../types/server';
import { getAddressFields, isAddressesField } from './addressFields';
import {
  addressSubfieldsPresets,
  EMAILS_FIELD_KEY,
  PHONES_FIELD_KEY,
  reservedFieldsPresets,
} from '../constants';
import { AddressSubfield, PublicInfoFieldKey } from '../../types';
import { getDisplayNameField, getTitleField } from './displayInfoFields';

const isPhonesField = (field: Pick<CustomField, 'key'>) =>
  field.key?.startsWith(PHONES_FIELD_KEY);
const isEmailsField = (field: Pick<CustomField, 'key'>) =>
  field.key?.startsWith(EMAILS_FIELD_KEY);

interface GetFieldWithValuedOptions {
  member: Member;
  field: CustomField;
  fieldsPrivacies: FieldPrivacy[];
  isPrivacyEditable?: boolean;
}

const getEmailOrContactFields = ({
  member,
  field,
  fieldsPrivacies,
  isPrivacyEditable,
}: GetFieldWithValuedOptions) => {
  const propName = getReservedFieldPropName(field)!;
  const defaultFieldValue = '';
  let fieldValues: string[];

  if (member.contact?.[propName] && member.contact[propName]!.length > 0) {
    fieldValues = member.contact[propName] as string[];
  } else {
    fieldValues = [defaultFieldValue];
  }

  const fields = fieldValues.map((fieldValue, fieldIndex) =>
    getCustomFieldWithValue({
      field: {
        ...field,
        id: `${field.id}${fieldIndex > 0 ? fieldIndex : ''}`,
      },
      fieldsPrivacies,
      value: fieldValue,
      index: fieldIndex,
      isPrivacyEditable,
    }),
  );

  return fields;
};

const getReservedFieldPropName = (field: Pick<CustomField, 'key'>) =>
  reservedFieldsPresets.find(({ key }) => key === field.key)?.contactPropName;

const isReservedField = (field: Pick<CustomField, 'key'>) =>
  reservedFieldsPresets.some(
    (reservedField) => field.key === reservedField.key,
  ) ||
  isTitleField(field) ||
  isDisplayNameField(field) ||
  isAddressesField(field);

const isDisplayNameField = (field: Pick<CustomField, 'key'>) =>
  field.key === PublicInfoFieldKey.DISPLAY_NAME;

const isTitleField = (field: Pick<CustomField, 'key'>) =>
  field.key === PublicInfoFieldKey.TITLE;

const getReservedFieldWithValue = ({
  member,
  field,
  fieldsPrivacies,
  isPrivacyEditable,
}: GetFieldWithValuedOptions) => {
  if (isDisplayNameField(field)) {
    return [getDisplayNameField(member.profile?.nickname)];
  }

  if (isTitleField(field)) {
    return [getTitleField(member.profile?.title)];
  }

  if (isAddressesField(field)) {
    return getAddressFields({
      member,
      field,
      fieldsPrivacies,
      isPrivacyEditable,
    });
  }

  if (isPhonesField(field) || isEmailsField(field)) {
    return getEmailOrContactFields({
      member,
      field,
      fieldsPrivacies,
      isPrivacyEditable,
    });
  }

  const propName = getReservedFieldPropName(field)!;
  const value = member.contact?.[propName];

  return [
    getCustomFieldWithValue({
      field,
      fieldsPrivacies,
      value,
      isPrivacyEditable,
    }),
  ];
};

const accountDataToContact = (accountData: AccountData) => {
  return accountData.fields
    .filter(isReservedField)
    .reduce<Partial<Contact>>((contact, field) => {
      const contactPropName = reservedFieldsPresets.find(
        (preset) => field.key === preset.key,
      )?.contactPropName;

      if (!contactPropName) {
        return contact;
      }

      let updatedField: Partial<Contact>;
      if (isAddressesField(field)) {
        const addresses = (field.value as AddressSubfield[]).reduce(
          (updatedAddresses, subfield) => {
            const addressPropName = addressSubfieldsPresets.find(
              (preset) => preset.key === subfield.key,
            )?.propName;

            if (!addressPropName) {
              return updatedAddresses;
            }

            const addressToUpdateIdx = updatedAddresses.findIndex(
              (address) => address.id === subfield.addressId,
            );

            if (addressToUpdateIdx !== -1) {
              updatedAddresses[addressToUpdateIdx][addressPropName] =
                typeof subfield.value === 'string'
                  ? subfield.value
                  : subfield.value?.selectedId;
              updatedAddresses[addressToUpdateIdx].id = subfield.addressId;
            } else {
              updatedAddresses.push({
                [addressPropName]: subfield.value,
                ...(subfield.addressId && { id: subfield.addressId }),
              });
            }

            return updatedAddresses;
          },
          (contact?.[contactPropName] ?? []) as Address[],
        );

        updatedField = {
          [contactPropName]: addresses,
        };
      } else if (isPhonesField(field) || isEmailsField(field)) {
        const currentValues = (contact?.[contactPropName] ?? []) as string[];
        const updatedValues = [...currentValues, field.value];
        updatedField = field.value
          ? { [contactPropName]: updatedValues }
          : contact;
      } else {
        updatedField = { [contactPropName]: field.value };
      }

      return { ...contact, ...updatedField };
    }, {});
};

export {
  accountDataToContact,
  getReservedFieldWithValue,
  isReservedField,
  isDisplayNameField,
  isTitleField,
};
