import React, { FC, FocusEventHandler } from 'react';
import { useField as useFormikField } from 'formik';

import { Text } from '../Text';
import { Field, FieldWrapper } from '../../../../../../types';

const hasProtocol = (url: string) => /^https?:\/\//i.test(url);
const addProtocol = (url: string) => `https://${url}`;
const withProtocol = (url: string) =>
  hasProtocol(url) ? url : addProtocol(url);

export const UrlField: FC<React.PropsWithChildren<FieldWrapper>> = (props) => {
  const [formikField, , helpers] = useFormikField<Field | null>(
    `${props.formikFieldSelector}`,
  );
  const field = formikField.value;

  if (!field) {
    return null;
  }

  const onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const urlWithProtocol =
      event.target?.value && withProtocol(event.target?.value);

    return helpers.setValue({
      ...field,
      value: urlWithProtocol,
    });
  };

  return <Text {...props} onBlur={onBlur} type="url" pattern="https://.*" />;
};
