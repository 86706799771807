import { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import { ToastSkin as TOAST_SKIN } from 'wix-ui-tpa';
import { Toast, ToastConfig } from '../../../../types';
import { Experiment } from '../../../../types/experiments';

type GetToastContentProps = {
  t: TFunction;
  toastConfig: ToastConfig;
  experiments: Experiments;
};

export const getToastContent = ({
  t,
  experiments,
  toastConfig,
}: GetToastContentProps) => {
  let toastSkin;
  let toastContent: string | null;

  switch (toastConfig.type) {
    case Toast.FORM_UPDATE_SUCCESS:
      toastContent = t('app.widget.toast.save-success');
      toastSkin = TOAST_SKIN.success;
      break;
    case Toast.FORM_UPDATE_ERROR_INTERNAL:
      toastContent = t('app.widget.toast.save-error-internal');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.FORM_UPDATE_ERROR_UNAUTHENTICATED:
      toastContent = t('app.widget.toast.save-error-unauthenticated');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.FORM_UPDATE_ERROR_UNKNOWN:
      toastContent = t('app.widget.toast.save-error-unknown');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.FORM_UPDATE_ERROR_INVALID_ARGUMENT:
      toastContent = t('app.widget.toast.save-error-invalid-argument');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.FORM_UPDATE_ERROR_UNAVAILABLE:
      toastContent = t('app.widget.toast.save-error-unavailable');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.FORM_UPDATE_ERROR_PERMISSION_DENIED:
      toastContent = t('app.widget.toast.save-error-permission-denied');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.FORM_UPDATE_ERROR_INVALID_URL:
      toastContent = t('app.widget.toast.save-error-invalid-url');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.PROFILE_URL_UPDATE_SUCCESS:
      toastContent = t('app.widget.toast.profile-url-update-success');
      toastSkin = TOAST_SKIN.success;
      break;
    case Toast.PROFILE_URL_UPDATE_ERROR:
      toastContent = t('app.widget.toast.profile-url-update-error');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.PROFILE_PRIVACY_CHANGE_SUCCESS:
      toastContent = t('app.widget.toast.profile-privacy-update-success');
      toastSkin = TOAST_SKIN.success;
      break;
    case Toast.PROFILE_PRIVACY_CHANGE_ERROR:
      toastContent = t('app.widget.toast.profile-privacy-update-error');
      toastSkin = TOAST_SKIN.error;
      break;
    case Toast.UNBLOCK_MEMBER_SUCCESS: {
      const shouldUseDynamicUnblockTranslationKey = experiments.enabled(
        Experiment.UseDynamicUnblockTranslation,
      );

      if (shouldUseDynamicUnblockTranslationKey) {
        toastContent = t(
          'app.widget.blocked-members.unblocked-dynamic-member-name',
          toastConfig.options,
        );
        toastSkin = TOAST_SKIN.success;
      } else {
        toastContent = t('app.widget.blocked-members.unblocked');
        toastSkin = TOAST_SKIN.success;
      }
      break;
    }
    case Toast.UNBLOCK_MEMBER_ERROR:
      toastContent = t('app.widget.blocked-members.unblock-error');
      toastSkin = TOAST_SKIN.error;
      break;
    default:
      toastContent = null;
      toastSkin = TOAST_SKIN.success;
  }

  return {
    toastContent,
    toastSkin,
  };
};
