import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa';

import { classes, st } from './SectionHeader.st.css';
import { DataHook } from '../../../../../constants/DataHook';
import { useEnvironment } from '@wix/yoshi-flow-editor';

type Props = {
  title: string | JSX.Element;
  subtitle?: string;
  className?: string;
};

export const SectionHeader: FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  className,
}) => {
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { mobile: isMobile }, className)}>
      <Text
        className={classes.title}
        data-hook={DataHook.SectionTitle}
        tagName="h2"
        id="members-area-page-title"
      >
        {title}
      </Text>
      {subtitle && (
        <div className={classes.subtitleWrapper}>
          <Text className={classes.subtitle}>{subtitle}</Text>
        </div>
      )}
    </div>
  );
};
