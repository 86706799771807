import React, { FC, useEffect } from 'react';
import {
  Toast as TPAToast,
  ToastPlacement as TOAST_PLACEMENT,
} from 'wix-ui-tpa';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { classes, st } from './Toast.st.css';
import { DataHook } from '../../../../constants/DataHook';
import { useUiActions, useUiState } from '../../../../contexts/widget';
import { getToastContent } from './getToastContent';

const TOAST_FADE_OUT_DELAY = 5000;

export const Toast: FC<React.PropsWithChildren<unknown>> = () => {
  const { toast } = useUiState();
  const { hideToast } = useUiActions();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  useEffect(() => {
    if (toast.isVisible) {
      const timeout = setTimeout(hideToast, TOAST_FADE_OUT_DELAY);
      return () => clearTimeout(timeout);
    }
  }, [hideToast, toast.isVisible]);

  const { toastContent, toastSkin } = getToastContent({
    t,
    experiments,
    toastConfig: toast,
  });

  return (
    <TPAToast
      skin={toastSkin}
      children={toastContent}
      isShown={toast.isVisible}
      className={st(classes.toast, {
        mobile: isMobile,
        displayNone: !toast.isVisible,
      })}
      data-hook={DataHook.Toast}
      shouldShowCloseButton={true}
      placement={
        isMobile ? TOAST_PLACEMENT.bottomFullWidth : TOAST_PLACEMENT.inline
      }
      onClose={() => hideToast()}
    />
  );
};
