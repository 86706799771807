import React, { createContext, FC, useEffect, useState } from 'react';
import { ModalType } from '../../types';
import { classes } from './Provider.st.css';

export type ModalContextType = {
  showModal<T>(
    modal: FC<React.PropsWithChildren<ModalType<T>>>,
    passThroughProps: T,
  ): void;
  showModal(modal: FC<React.PropsWithChildren<ModalType>>): void;
  hideModal(): void;
};

export const ModalsContext = createContext<ModalContextType | null>(null);

export const ModalsContextProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [Modal, setModal] = useState<FC<ModalType<unknown>> | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<unknown>();

  useEffect(() => {
    if (Modal) {
      setIsOpen(true);
    }
  }, [Modal]);

  useEffect(() => {
    if (!isOpen) {
      setModal(null);
    }
  }, [isOpen]);

  const value = {
    showModal: <T,>(
      modal: FC<React.PropsWithChildren<ModalType<T>>>,
      additionalProps?: T,
    ) => {
      setModal(() => modal as FC<React.PropsWithChildren<ModalType<unknown>>>);
      setProps(additionalProps);
    },
    hideModal: () => {
      setIsOpen(false);
    },
  };

  return (
    <ModalsContext.Provider value={value}>
      <>
        {children}
        {Modal && (
          <div className={classes.modalWrapper}>
            <Modal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              additionalProps={props}
            />
          </div>
        )}
      </>
    </ModalsContext.Provider>
  );
};
